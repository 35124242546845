export enum ManagementOperation {
	ENLACE_NUEVO = 100000000,
	MIGRATION = 100000001,
	UPGRADE = 226530001,
	DOWNGRADE = 100000003,
	CAMBIO_TECNOLOGIA = 100000004,
	CAMBIO_PRECIO = 100000005,
	RENOVACION = 100000006,
	UPGRADE_MIGRACION = 100000007,
	UPGRADE_CAMBIO_TECNOLOGIA = 100000008,
	DOWNGRADE_MIGRACION = 100000009,
	DOWNGRADE_CAMBIO_TECNOLOGIA = 100000010,
	MIGRATION_TECNOLOGIA = 100000011,
	UPGRADE_MIGRATION_CAMBIO_TECNOLOGIA = 100000012,
	DOWNGRADE_MIGRATION_CAMBIO_TECNOLOGIA = 100000013,
	MODIFICATION = 226530000,
	BAJA_PRECIO = 226530020,
	AUMENTO_PRECIO = 226530021,
	AUMENTO_PLAZO = 226530009,
	DISMINUCION_PLAZO = 226530010,
	TRASLADO = 226530007,
	CAMBIO_TIPO_SERVICIO = 226530002,
	CAMBIO_MEDIO_TRANSMISION = 226530004,
	SOLICITUD_ESPECIAL = 1,
}
